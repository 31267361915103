import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PanelBody from './PanelBody';
import CelebrationContainer from './CelebrationContainer';
import ChangeToChartButton from '../ChangeToChart';
import RecalculatedQuotaCheckbox from './RecalculatedQuotaCheckbox';

import { formatPrice } from '~/util/format';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';

import * as Style from './styles';
import { NV } from '~/util/brands';

function MainPanelCard({
    balanceText,
    salesNumber,
    quota,
    salesPercentage,
    aggregatedOrdersByPeriod,
    handleFlipped,
    showIcon,
    hideSuperQuota,
}) {
    const { finalDate, period } = useSelector(state => state.status);
    const userProfile = useSelector(state => state.user.profile);

    const [daysLeftInPeriod, setDaysLeftInPeriod] = useState(0);
    const [showRecalculatedQuota, setShowRecalculatedQuota] = useState(false);
    const [isLastDay, setIsLastDay] = useState(false);
    const [hideDaysLeftMessage, setHideDaysLeftMessage] = useState(false);

    const monthlyAggregatedOrders = aggregatedOrdersByPeriod?.find(
        periodObj => periodObj.period === 'month'
    );
    const monthlyQuotas = quota?.periodQuotas?.find(
        periodObj => periodObj.period === 'month'
    );
    const quotaInCurrentMonth = monthlyQuotas?.quota;
    const salesInCurrentPeriod =
        monthlyAggregatedOrders?.sales?.salesGroupedByChannelAndDay?.total?.value +
        monthlyAggregatedOrders?.sales?.salesGroupedByChannelAndDay?.returns?.value;
    const superQuotaMultiplier = monthlyQuotas?.superQuotaMultiplier
        ? monthlyQuotas?.superQuotaMultiplier
        : 1;
    const hasAchievedMonthlyQuota =
        salesInCurrentPeriod >= quotaInCurrentMonth && quotaInCurrentMonth;
    const hasAchievedMonthlySuperQuota =
        superQuotaMultiplier &&
        salesInCurrentPeriod >= quotaInCurrentMonth * superQuotaMultiplier &&
        quotaInCurrentMonth;
    const shouldShowCongratulationMessage =
        (hasAchievedMonthlySuperQuota || hasAchievedMonthlyQuota) &&
        period !== 'custom';

    const quotaInCurrentPeriod = monthlyQuotas?.quotaUntilEndDate;
    const newSalesExtra = salesInCurrentPeriod - quotaInCurrentPeriod;
    const salesExtra = {
        value: newSalesExtra,
        color: newSalesExtra > 0 ? '#1BB55C' : '#E26257',
        valueAsPrice: formatPrice(newSalesExtra),
        secondaryText: newSalesExtra > 0 ? 'mais' : 'menos',
    };
    const newRecalculatedQuota =
        quota?.totalQuotaAsNumber - salesExtra?.value / daysLeftInPeriod;
    const recalculatedQuota = newRecalculatedQuota
        ? newRecalculatedQuota
        : undefined;
    const NVQuotaBarCondition =
        userProfile?.marcaFilial === NV &&
        period === 'month' &&
        shouldShowCongratulationMessage &&
        !hideSuperQuota;
    const showDaysLeftFooter =
        !hideDaysLeftMessage &&
        daysLeftInPeriod <= 31 &&
        !showRecalculatedQuota;

    const showCelebrationContainer = () => {
        if (shouldShowCongratulationMessage && period === 'month') {
            return (
                <CelebrationContainer
                    salesNumber={salesNumber}
                    monthlyQuotas={monthlyQuotas}
                />
            );
        }
    };

    const showRecalculatedQuotaCheckbox = () => {
        if (
            period === 'day' &&
            moment().isSame(finalDate, 'day') &&
            !shouldShowCongratulationMessage
        ) {
            return (
                <RecalculatedQuotaCheckbox
                    showRecalculatedQuota={showRecalculatedQuota}
                    setShowRecalculatedQuota={setShowRecalculatedQuota}
                />
            );
        }
    };

    useEffect(() => {
        if (finalDate) {
            let endOfMonth = moment(finalDate).endOf('month');

            if (moment(finalDate).date() >= 26) {
                endOfMonth = endOfMonth.add('month', 1);
            }

            let assessmentDates;

            if (userProfile?.marcaFilial === 16) {
                assessmentDates = getAssessmentDatesByDate(endOfMonth, 16);
            } else {
                assessmentDates = getAssessmentDatesByDate(endOfMonth);
            }

            if (assessmentDates) {
                const FIRST_DAY_OF_PERIOD = assessmentDates.endDate;
                const newDaysLeftInPeriod =
                    FIRST_DAY_OF_PERIOD.diff(moment(), 'days') + 1;
                setDaysLeftInPeriod(newDaysLeftInPeriod);

                if (moment(finalDate).isSame(assessmentDates.endDate, 'day')) {
                    setIsLastDay(true);
                } else {
                    setIsLastDay(false);
                }
            }
            if (period === 'month') {
                if (moment(finalDate).isSame(assessmentDates.endDate, 'day')) {
                    setIsLastDay(true);
                } else {
                    setIsLastDay(false);
                }
                if (
                    moment().isSameOrAfter(assessmentDates.startDate, 'day') &&
                    moment().isSameOrBefore(assessmentDates.endDate, 'day') &&
                    period !== 'custom'
                ) {
                    setHideDaysLeftMessage(false);
                } else {
                    setHideDaysLeftMessage(true);
                }
            }
            if (period === 'day') {
                if (moment().isSame(finalDate, 'day')) {
                    setHideDaysLeftMessage(false);
                } else {
                    setHideDaysLeftMessage(true);
                }
            }
            if (['custom', 'week'].includes(period)) {
                setHideDaysLeftMessage(true);
            }
        }
    }, [finalDate, period, userProfile.marcaFilial]);

    return (
        <Style.PanelCardContainer
            container
            primary={!!shouldShowCongratulationMessage}
        >
            <ChangeToChartButton
                showIcon={showIcon}
                handleFlipped={handleFlipped}
            />
            <Style.BalanceTextContainer
                item
                primary={!!shouldShowCongratulationMessage}
            >
                {balanceText}
            </Style.BalanceTextContainer>
            <Style.InnerPanelCardContainer item container primary={period === 'day'}>
                <Grid item style={{ width: '95%' }}>
                    {showCelebrationContainer()}
                    {!NVQuotaBarCondition && (
                        <PanelBody
                            quota={quota}
                            salesNumber={salesNumber}
                            shouldShowCongratulationsMessage={
                                shouldShowCongratulationMessage
                            }
                            showRecalculatedQuota={showRecalculatedQuota}
                            salesPercentage={salesPercentage}
                            recalculatedQuota={recalculatedQuota}
                            isLastDay={isLastDay}
                        />
                    )}
                    {!shouldShowCongratulationMessage &&
                        period === 'month' &&
                        moment().isSame(finalDate, 'day') &&
                        !NVQuotaBarCondition && (
                            <PanelBody
                                quota={quota}
                                salesNumber={salesNumber}
                                shouldShowCongratulationsMessage={
                                    shouldShowCongratulationMessage
                                }
                                showRecalculatedQuota={showRecalculatedQuota}
                                salesPercentage={salesPercentage}
                                recalculatedQuota={recalculatedQuota}
                                isLastDay={isLastDay}
                                secondaryProgressView={true}
                            />
                        )}
                    {showRecalculatedQuotaCheckbox()}
                </Grid>
                {showRecalculatedQuota && (
                    <Grid container justifyContent="space-between">
                        <Style.DividerComponent />
                        <Typography
                            style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                textAlign: 'left',
                                display: 'inline',
                            }}
                        >
                            Vendido a {salesExtra?.secondaryText} no período
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '14px',
                                fontWeight: '700',
                                textAlign: 'right',
                                paddingLeft: '8px',
                                color: salesExtra?.color,
                                display: 'inline',
                            }}
                        >
                            {salesExtra?.valueAsPrice}
                        </Typography>
                    </Grid>
                )}
                {showDaysLeftFooter && (
                    <Grid container justifyContent="space-between">
                        <Style.DividerComponent />
                        <Style.FooterTypography>
                            {daysLeftInPeriod === 1 ? 'Falta' : 'Faltam'}{' '}
                            <strong>{daysLeftInPeriod}</strong> dias para o fim
                            do período.
                        </Style.FooterTypography>
                    </Grid>
                )}
            </Style.InnerPanelCardContainer>
        </Style.PanelCardContainer>
    );
}

export default MainPanelCard;
